/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
:root {
    --green_general: #6FCF97;
    --red_general: #FF5858;
    --blue_general: #4D7CFE;
    --border_general: #E5E8EF;
    --border_general1: #E7EFF0;

}
.ReportModule-container-parent-class h1,
.ReportModule-container-parent-class h2, 
.ReportModule-container-parent-class h3,
.ReportModule-container-parent-class h4,
.ReportModule-container-parent-class h5,
.ReportModule-container-parent-class h6,
.ReportModule-container-parent-class p{
    padding: 0;
    margin: 0;
}

.MuiInputBase-root{
    font-family: var(--poppins) !important;
}

.user__report_page_wrapper {
    background: var(--white);
    border-radius: 10px;
    padding: 26px 20px 1px 20px;
}


.user__report_page_wrapper .pagination_bar {
    padding: 10px 0 20px;
}
.user__report_page_wrapper .pagination_bar ul  {
   display: flex;
   align-items: center;
   justify-content: center;
}
.user__report_page_wrapper .pagination_bar ul li a {
    color:#fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 6px 13px;
    margin-left:10px;
    display: block;
    border:2px solid #3C7EF3;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.user__report_page_wrapper .paginations_text{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}
.user__report_page_wrapper .pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border:2px solid #3C7EF3;
    color:#3C7EF3;
}
.user__report_page_wrapper .pagination_bar ul li:nth-child(3) a {
    background: transparent;
    color:#3C7EF3;
    border: unset;
    padding: 0px 6px;
}
.user__report_page_wrapper .pagination_bar ul li:nth-child(3) a span {
    margin-right: 5px;
}
.user__report_page_wrapper .icon_text{
    gap: 10px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    font-weight: normal;
}

.user__report_page_wrapper .user__report__pagination__ul .active input{
    border: 2px solid #3c7ef3;
    width: 70px;
    height: 48px;
    border-radius: 4px;
    padding: 5px;
    margin-left: 12px;
    margin-bottom: 0!important
  }

.user__report_page_wrapper .userBox .user_order_list_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0 0 16px 0 !important;
    align-items: center;
    border-bottom: 1.5px solid #d6d5d5;
}

.user__report_page_wrapper .details {
    padding: 0 0 26px 0 !important;
    border-bottom: 1.5px solid #d6d5d5;
}


.user__report_page_wrapper .details tr:last-child {
    border-bottom: none !important;
  }

pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }

/* Chart */

.user__report_page_wrapper .user__order__chart__wr {
    margin: 14px 0 4px 0;
    border-top: 1.5px solid #E5E8EF;
}

.user__report_page_wrapper .user__order__chart__wr .batches_report_table {
    border-spacing: 0 10px;
}


/* Search */

.ReportModule-container-parent-class .user__order__list__filter__area{
    display: flex;
    flex: 1;
    grid-gap: 16px;
}
.ReportModule-container-parent-class .each__date.each__date--startdate,
.ReportModule-container-parent-class .each__date.each__date--endDate
 { 
    width: 100%;
 }
 .ReportModule-container-parent-class .each__date .MuiInputBase-root{
    border: 1px solid #ddd;
    border-radius: 4px;
 }
 .ReportModule-container-parent-class .each__date .MuiInputBase-root .MuiInputBase-input{
    padding: 10px 16px;
    margin: 0;
    height: unset;
 }
 .ReportModule-container-parent-class .each__date .MuiFormControl-root{
    margin: 0 !important;
 }
.ReportModule-container-parent-class .user__order__list__filter__area .filter__menu__area,
.ReportModule-container-parent-class .user__order__list__filter__area .filter__date__area
{
    width: 32%;
}
.ReportModule-container-parent-class .user__order__list__filter__area .filter__menu__area,
.ReportModule-container-parent-class .user__order__list__filter__area .filter__date__area 
{
    display: flex;
    align-items: center;
    grid-gap: 16px;
    flex: 1;
}
.ReportModule-container-parent-class .filter__menu__area .each__dropdown .MuiInputBase-root{
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.ReportModule-container-parent-class .filter__menu__area .each__dropdown .MuiInputBase-root .MuiSelect-root{
    border: 0 !important;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: var(--dark_blue);
}

.ReportModule-container-parent-class .each__date button{
    border: 1px solid #ced4da;
    padding: 10px 26px 10px 12px;
    position: relative;
    border-radius: 4px;
    background: #fff;
    width: 100%;
    height: 39px;
    text-align: left;
    color: var(--dark_blue);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ReportModule-container-parent-class .user__order__list__filter__area .each__dropdown{
    width: 100%;
    max-width: 100%;
}

.ReportModule-container-parent-class  .business__chart__wr .business__chart__wr__h4__title {
    font-size: 20px;
    color: #454818;
}

.ReportModule-container-parent-class  .business__chart__wr .order__chart__content {
    margin: 50px 0 20px 0;
}

.ReportModule-container-parent-class  .business__chart__wr .order__chart__content .order__chart__title {
    font-size: 20px;
    color: #454818;
    margin-bottom: 35px;
}

.ReportModule-container-parent-class  .order__history__count__wr {
    display: grid;
    font-family: var(--poppins);
    grid-template-columns: repeat( auto-fit, minmax(196px, 1fr) );
    grid-gap: 16px;
    margin: 24px 0 24px 0;
}

.ReportModule-container-parent-class .user__order__list__filter__serch__area {
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    margin: 60px 0 6px 0;
}

.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single{
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
    border-radius: 4px;
}
.ReportModule-container-parent-class .order__history__count__single .order__history__title{
    font-family: var(--poppins);
    letter-spacing: 0.16px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
}
.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single .order__history__title.PENDING{
    color: #f1c40f;
}
.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single .order__history__title.CANCELLED,
.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single .order__history__title.FAILED
{
    color: var(--red);
}
.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single .order__history__title.PRINTING{
    color: #3498db;
}
.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single .order__history__title.PRINTED{
    color: #27ae60;
}
.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single .order__history__title.ERROR{
    color: #ff6b6b;
}
.ReportModule-container-parent-class .order__history__count__wr .order__history__count__single .order__history__title.SHIPPED{
    color: #81ecec;
}


.ReportModule-container-parent-class .order__history__count__single .order__history__number{
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.16px;
}
.ReportModule-container-parent-class .order__history__count__single .order__history__h6{
    font-family: var(--poppins);
    color: #979797;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    padding: 4px 0px 1px !important;
}
.ReportModule-container-parent-class .order__history__filter__btn{
    cursor: pointer;
    background: #fff;
    color: #fff;
    border-radius: 4px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
    border:1px solid #ddd;
}
.ReportModule-container-parent-class .order__history__filter__btn svg {
    width: 20px;
}
.ReportModule-container-parent-class .order__history__filter__btn svg path{
    stroke: var(--dark_blue);
}
.ReportModule-container-parent-class .user__order__list__search__area .user__order__list__search{
    height: 39px !important;
    border-color: #ddd !important;
}


/* table design */
.ReportModule-container-parent-class .user__order__table__wr tr.MuiTableRow-root td:nth-child(2){
    max-width: 238px;
}
.ReportModule-container-parent-class .user__order__table__wr tr.MuiTableRow-root td:nth-child(3),
.ReportModule-container-parent-class .user__order__table__wr tr.MuiTableRow-root td:nth-child(4),
.ReportModule-container-parent-class .user__order__table__wr tr.MuiTableRow-root td:nth-child(5),
.ReportModule-container-parent-class .user__order__table__wr tr.MuiTableRow-root td:nth-child(6),
.ReportModule-container-parent-class .user__order__table__wr tr.MuiTableRow-root td:nth-child(7)
{
    width: 13%;
}
.ReportModule-container-parent-class .user__order__table__wr .chart__table__body .MuiTableCell-root{
    font-size: 14px;
    text-align: center;
}
.ReportModule-container-parent-class .user__order__table__wr .report_recipient{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ReportModule-container-parent-class .chart__table__body__tr .report_recipient_top{
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
}
.ReportModule-container-parent-class .chart__table__body__tr .report_recipient_bottom{
    font-size: 13px;
    font-weight: 400;
    color: #039be5;
    white-space: normal !important;
    word-break: break-word;
    width: 65%;
    margin: 0 auto;
}
.ReportModule-container-parent-class .chart__table__wr.user__order__table__wr{
    border: 0 !important;
    margin: 0;
}
.ReportModule-container-parent-class .chart__table__wr.user__order__table__wr .chart__table{
    margin-top: 14px !important;
}
.ReportModule-container-parent-class .user__order__table__wr .MuiTableHead-root .MuiTableCell-root{
    background: #ddd;
    border-radius: 0 !important;
    font-size: 14px;
    color: var(--dark_blue) !important;
    font-weight: 500;
    text-align: center;
}
.ReportModule-container-parent-class .user__order__table__wr .MuiTableHead-root .MuiTableCell-root:first-child{
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}
.ReportModule-container-parent-class .user__order__table__wr .MuiTableHead-root .MuiTableCell-root:last-child{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.ReportModule-container-parent-class .user__order__table__wr .delivery__status__btn__v2{
    border: 1px solid #ddd;
    border-radius: 4px;
    text-transform: capitalize !important;
    padding: 5px;
    width: 100%;
    max-width: 100%;
    display: block;
    text-align: center;
}

.ReportModule-container-parent-class .user__order__table__wr .delivery__status__btn__v2 svg{
    width: 15px;
    height: 15px;
    fill: #fff;
}

.ReportModule-container-parent-class .user__order__table__wr .delivery__status__btn__v2.info__icon{
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    max-width: 130px;
    margin: 0 auto;
    font-weight: 500;
    border: 0 !important
}

.ReportModule-container-parent-class .user__order__table__wr .delivery__status__cancel__btn{
    background: var(--red);
    color: #fff;
}
.ReportModule-container-parent-class .user__order__table__wr .delivery__status__pending__btn{
    background: #f1c40f;
    color: #fff;
}
.ReportModule-container-parent-class .user__order__table__wr .delivery__status__error__btn{
    background: #ff6b6b;
    color: #fff;
}
.ReportModule-container-parent-class .user__order__table__wr .delivery__status__failed__btn{
    background: var(--red);
    color: #fff;
}
.ReportModule-container-parent-class .user__order__table__wr .delivery__status__printed__btn{
    background: #27ae60;
    color: #fff;
}
.ReportModule-container-parent-class .user__order__table__wr .delivery__status__printing__btn{
    background: #3498db;
    color: #fff;
}

.ReportModule-container-parent-class .user__order__table__wr .delivery__status__shipped__btn{
    background: #81ecec;
    color: #fff;
}
.ReportModule-container-parent-class .user__order__table__wr .order__history__content__type{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
}

.ReportModule-container-parent-class .user__order__table__wr .order__history__gift{
   background: #27ae60;
    padding: 0px 6px;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    font-size: 11px;
    text-align: center;
}
.ReportModule-container-parent-class .user__order__table__wr .order__history__cardsize{
    background: #f39c12;
    padding: 0px 6px;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    font-size: 11px;
    text-align: center;
}

.ReportModule-container-parent-class .user__order__table__wr .order__history__not__available{
    margin-left: 60px;
    font-size: 16px;
    font-weight: 600;
    color: #34495e;
}

.ReportModule-container-parent-class .user__order__table__wr .order__history__total__cost {
    font-size: 16px;
    font-weight: 600;
    color: #34495e !important;
    display: grid;
    place-items: center;
}

.ReportModule-container-parent-class .user__order__table__wr .order__history__file__preview img{
   width: 150px;
    height: 150px;
    padding: 5px;
    object-fit: contain;
}

.ReportModule-container-parent-class .user__order__table__wr .order__history__file__preview svg{
    width: 150px;
    height: 150px;
}

@media screen and (max-width: 1582px) {
    .ReportModule-container-parent-class .chart__table__body__tr .report_recipient_bottom{
        width: 100%;
    }
}


@media only screen and (min-width: 992px) and (max-width:1357px){
    .ReportModule-container-parent-class .user__order__list__filter__area{
        flex: 2;
    }
    .ReportModule-container-parent-class .user__report_page_wrapper .user__order__list__search__area{
        flex: 0.5;
    }
}
@media only screen and (min-width: 992px) and (max-width:1200px){
    .ReportModule-container-parent-class .user__order__list__filter__area .each__dropdown{
        max-width: 159px;
    }
}
@media only screen and (max-width: 992px){
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content{
        width: 100%;
        margin-left: 0;
    }
    .ReportModule-container-parent-class .user__order__list__filter__serch__area{
        flex-direction: column-reverse;
    }
    
}


