.ReportModule-container-parent-class .business__report__view__v2__container .business__report__top__header__container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border_general1);
    margin-bottom: 20px;
    padding-bottom: 26px;
}
.ReportModule-container-parent-class .business__report__view__v2__container .top__header__filter{
    display: flex;
    align-items: center;
    grid-gap: 16px;
}
.ReportModule-container-parent-class  .top__header__filter__select fieldset{
    border-color: var(--dark_blue) !important;
}
.ReportModule-container-parent-class  .top__header__filter__select .business_select{
    color: #fff !important;
}
.ReportModule-container-parent-class .business__report__chart__view__container .business__chart__view .MuiSelect-select,
.ReportModule-container-parent-class .top__header__filter__select .MuiSelect-select{
    padding: 13.5px 40px 13.5px 10px!important;
    font-size: 12px;

}
.ReportModule-container-parent-class  .top__header__filter__select .business_select svg{
    width: 22px !important;
    height: 22px !important;
    right: 0 !important;
}

.ReportModule-container-parent-class .top__header__filter .top__header__filter__add__col .top__header__filter__add__btn{
    background: var(--dark_blue);
    color: #fff;
    height: 45px;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 12px;
    cursor: pointer;
}
.ReportModule-container-parent-class  .business__report__view__v2__container {
    background: #fff;
    border-radius: 6px;
    padding: 26px 20px 1px;
}
.ReportModule-container-parent-class .business__report__top__header__container .top__header__title{
    font-family: var(--poppins);
    font-size: 18px;
    font-weight: 500;
    color: var(--dark_blue);
    line-height: 36px;
    letter-spacing: 0.16px;
    max-width: 34%;
}
.ReportModule-container-parent-class  .business__chart__view .business__report__chart__view__top__header{
    display: flex;
    grid-gap: 10px;
    /* margin-bottom: 20px; */
    margin-bottom: -10px;
}
.ReportModule-container-parent-class  .business__chart__view .business__report__chart__view__top__header fieldset{
    border-color: var(--dark_blue) !important;
}
.ReportModule-container-parent-class  .business__report__chart__view__top__header .business_select{
    color: #fff !important;
}
.ReportModule-container-parent-class  .business__report__chart__view__top__header  .business_select svg{
    width: 22px !important;
    height: 22px !important;
    right: 0 !important;
}

.ReportModule-container-parent-class .business__report__chart__view__container .business__custom__loader{
    height: 245px;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Card Design start */
.ReportModule-container-parent-class .business__report__card__view__container .business__chart__view__row{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(180px, 1fr) );
    grid-gap: 20px;
}
.ReportModule-container-parent-class .business__report__card__view__container .business__report__card__each__item__container {
    background: var(--white);
    border: 1px solid var(--border_general);
    padding: 8px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
}
.ReportModule-container-parent-class .business__report__card__view__container{
    margin-top: 35px;
    margin-bottom: 35px;
}
.ReportModule-container-parent-class .business__report__card__view__container .business__report__card__each__item__title{
    font-family: var(--poppins);
    color: var(--dark_blue);
    letter-spacing: .16px;
    text-transform: capitalize;

}
.ReportModule-container-parent-class .business__report__card__view__container .business__report__card__each__item__value{
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: .16px;
}
.ReportModule-container-parent-class .business__report__card__view__container .business__report__card__each__item__percentage{
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: .16px;
    padding: 4px 0 1px!important;
}

.ReportModule-container-parent-class .business__report__card__view__container .business__report__card__each__item__icon{

}
.ReportModule-container-parent-class .business__report__card__view__container .business__custom__loader{
    height: 245px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ReportModule-container-parent-class .business__report__chart__view__container,
.ReportModule-container-parent-class .business__report__card__view__container
{
    background-color: #f4f5f6;
    padding: 5px;
    border-radius: 4px
}



