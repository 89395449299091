.chart__count__wr {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    font-family: var(--poppins);
}
.chart__count__single{
    background: var(--white);
    border: 1px solid var(--border_general);
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 178px;;
    padding: 0 8px;
    text-align: center;
    margin: 0 19px 20px 0;
}

.custom__tooltip {
    /* visibility: visible !important;
    opacity: 1 !important; */
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0.16px !important;
    font-family: var(--poppins) !important;
}
/* .chart__count__wr .chart__count__single:first-child{
    margin-left: 0px !important;
}
.chart__count__wr .chart__count__single:last-child{
    margin-right: 0px !important;
} */
.count__title{
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.16px;
    padding-top: 8px !important;
    text-transform: capitalize;
}
.add__column__title{
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-transform: capitalize;
    margin-top: 14px !important;
}

.count__number{
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.16px;}
.up__arrow{
    height: 26px;
    width: 26px;
    color: var(--white);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.up__arrow__green{
    background: var(--green_general);
}
.up__down__red{
    background: var(--red_general);
}
.up__arrow__blue{
    background: var(--blue_general);
}
.count__precent{
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    padding: 4px 0 1px 0 !important;
}
.count__precent__devide{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 1%;
}
.chart__count__wr .MuiGrid-grid-xs-2{
    flex-basis: 12.50% !important;
}

.count__line canvas{
    height: 80px !important;
}

.add__chart__count__single{
    min-height: 180px;
    width: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


@media(max-width: 991px){
    .count__precent{
        font-weight: 400 !important;
        line-height: 21px !important;
    }
}

@media(max-width: 768px){
    
    .up__arrow{
        height: 26px;
        width: 26px;
        margin: 3px 0 4px 0;
    }
    .count__precent{
        font-size: 14px;
        padding: 13px 0 1px 0 !important;
    }
    .count__precent__devide{
        font-size: 10px;
    }
    .count__title{
        font-size: 12px ;
    }
    .count__number{
        font-size: 24px;
        line-height: 36px ;
    }
    .count__line{
        margin-top: -22px !important;
    }
    .chart__count__single {
        min-width: 140px;
        padding: 0 8px;
        text-align: center;
        height: 200px !important;
    }
    .add__chart__count__single{
        min-width: 140px;
        width: 140px;
        padding: 0 8px;
        text-align: center;
        height: 200px;
    }
    .add__column__title{
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }
} 