/*table css start*/

.ReportModule-container-parent-class .power-dialer-call_logs_ria .meis_ctmw_fw_title{
    font-weight: 600;
    font-size: 18px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .meis_ctmw_filter_wrp .meis_ctmw_fw_sw_input {
    height: 42px;
    font-size: 14px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp_con {
    margin-top: unset;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp_con thead th {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp_con thead th:not(:first-child) {
    width: 170px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp_con .MuiSvgIcon-root{
    height: 16px;
    width: 16px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .meis_custom_table_main_wrapper .meis_ctmw_table_wrp tr td {
    line-height: inherit;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .meis_custom_table_main_wrapper .meis_ctmw_table_wrp_con tbody tr td{
    border-radius: unset !important;
    border-bottom: 1px solid #949DB23D !important;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table{
    border-spacing: unset !important;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .meis_custom_table_main_wrapper .meis_ctmw_filter_wrp {
    border-bottom: none;
    padding-bottom: 0 !important;
    margin-bottom: 12px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_fw_sw_input {
    border-radius: 4px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_fw_sw_left_icon{
    fill: #aaaaaa;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table tbody tr:hover{
    background-color: #f5f5f5;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp tr td {
    background-color: unset;
}

/*table css end*/

/* for custom pagination */
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 0 13px;

    position: relative;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .power-dialer-call-logs_pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;

    position: absolute;
    left: 40%;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_back_button,
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_next_button,
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_input_field{
    width: 40px;
    height: 40px;
    display: inline-flex;
    border: 1px solid rgba(98,88,255,0.5);
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px;
    margin: 0 !important;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_input_field:focus{
    box-shadow: none !important;
    border-color: rgba(98,88,255,0.5) !important;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_input_field_total{
    width: 40px;
    height: 40px;
    display: inline-flex;
    border: 1px solid rgba(148,157,178,.24);
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px;
    margin: 0 !important;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_divider{
    position: relative;
    margin: 0 10px;
    color: rgba(148,157,178,.24);
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_divider:after{
    position: absolute;
    content: "";
    top: -14px;
    left: 0;
    width: 1px;
    background: #ddd;
    height: 30px;
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_back_button,
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_next_button{
    cursor: pointer;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_bb_disabled{
    opacity: .5;
    cursor: not-allowed!important;
}
/*end-----*/


.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_perpage_body .clpb_icon{
    display: inline-flex;
}


.ReportModule-container-parent-class .power-dialer-call_logs_ria .mlvc_campaign_folder_inner_single_menu .new_folder_c_menu_list{
    display: flex;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .mlvc_campaign_folder_inner_single_menu .new_f_c_over_menu_item_label_default{
    display: flex;
    align-items: center;
    justify-self: center;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .mlvc_campaign_folder_inner_single .pype_custom_menu_container,
.ReportModule-container-parent-class .power-dialer-call_logs_ria .mlvc_campaign_folder_inner_single:hover .mlvc_campaign_folder_inner_single_menu{
    display: inline-flex;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_perpage_body {
    display: flex;
    align-items: center;
    max-width: 80px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #8B9ECC3D;
    border-radius: 4px;
    padding: 3px 10px;
    gap: 26px;

    font-size: 14px;
}


.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call_logs_table_pagination .rftp_table_per_page_select {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--text-secondary-color);
    align-items: center;
    gap: 8px;
}

.new_folder_c_over_container .new_f_c_over_menu_item {
    display: flex;
    grid-gap: 8px;
    font-size: 14px;
    color: #546376;
    padding: 5px 2px;
    margin-bottom: 2px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

.power-dialer-call_logs_ria .new_folder_c_over_container.awesome__scroll_bar {
    padding: 11px 10px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .success__capsule {
    background-color: #BAF0CB;
    color: #006625 !important;
    width: 85px;
    text-align: center;
    height: 26px;
    border-radius: 16px;
    padding: 3px 4px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .failed__capsule {
    background-color: #FFDAD9;
    color: #940015 !important;
    width: 85px;
    text-align: center;
    height: 26px;
    border-radius: 16px;
    padding: 3px 4px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .queued__capsule {
    background-color: #FFDCC3;
    color: #944200 !important;
    width: 85px;
    text-align: center;
    height: 26px;
    border-radius: 16px;
    padding: 3px 4px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_display_name{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgb(84, 99, 118);
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_display_icon{
    margin-right: 10px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .log_contact_number{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: rgb(84, 99, 118);
    margin-left: 4px;
    white-space: normal !important;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .log_contact_email_email{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: rgb(84, 99, 118);
    margin-left: 4px;
}

@media only screen and (max-width: 1366px){
    .ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp_con thead th {
        font-size: 12px;
        line-height: 22px;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp tr td .power-dialer-call-logs_th--value{
        font-size: 12px;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .power-dialer-call-logs_details, .power-dialer-call-logs_details p {
        font-size: 11px !important;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_display_name {
        font-size: 12px;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria .log_contact_number {
        font-size: 11px !important;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria .log_contact_email_email {
        font-size: 11px !important;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp tr td .power-dialer-call-logs_th--value{
        font-size: 12px;
        text-align: center;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp thead th:not(:first-child){
        text-align: center;
    }

    .ReportModule-container-parent-class .power-dialer-call_logs_ria.meis_custom_table_main_wrapper .meis_ctmw_table_wrp thead th:first-child{
        padding-left: 20px;
    }
}

/* new update css start :: 02-08-2022 */
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_select_wrapper_v2 .power-dialer-call-logs_datepicker_extra {
    padding: 17px;
    margin: 0 auto;
    z-index: 9993;
    background: #E5E8EF;
    border-radius: 5px;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_select_wrapper_v2 .power-dialer-call-logs_datepicker_extra .select-wrapper{
    position: relative;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_select_wrapper_v2 .power-dialer-call-logs_datepicker_extra .date__action_button{
    position: absolute;
    z-index: 1;
    right: -20px;
    top: -18px;
    cursor: pointer;
}
.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_select_wrapper_v2 .power-dialer-call-logs_datepicker{
    padding: 17px;
    background: #E5E8EF;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_datepicker{
    position: absolute;
    right: 40px;
    top: 40px;
    display: flex;
    gap: 10px;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .report_type__select{
    width: 134px !important;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .report_status__select{
    width: 114px !important;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .second__step__area__select.assign__stage_select_wrapper .MuiSelect-select:focus {
    height: unset !important;
    background: unset !important;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .droupdown__helper .MuiFormControl-root.second__step__area__select.second__step__area__select__100 {
     height: unset !important;
}

.ReportModule-container-parent-class .power-dialer-call_logs_ria .power-dialer-call-logs_filter .MuiInputBase-root {
    font-weight: 600;
    font-size: 14px;
    color: #445974 !important;
}

