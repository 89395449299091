.goal__activies__wr{
    background: var(--gray_light);
    border: 1px solid #E5E8EF;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
}
.goal__activies__top{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--white);
    padding-bottom: 10px;
}
.goal__activies__top .goal__activites__title{
    display: flex;
    color: var(--dark_blue);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.16px;

}
.goal__activites__title .goal__activites__subtitle{
    color: var(--light_blue);
    margin: 0 20px 0 8px;
}
.goal__activites__title .goal__activites__edit{
    cursor: pointer;
}
.goal__activites__top__right{}
.goal__activites__top__right .goal__activites__top__icon1{
    cursor: pointer;
}
.goal__activites__top__right .goal__activites__top__icon2{
    cursor: pointer;
    margin-left: 10px;
}

.goal__activies__content {
    padding-top: 10px;
}
.goal__activites__list__single {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.16px;
    padding-bottom: 20px;
}
.activites__name__label{
    width: 180px;
    color: var(--dark_blue);
}
.activites__name__content{
    color: var(--gray);
    display: flex;
    align-items: center;
}



/* For Select Start */
.activities_select__wr .MuiInputBase-formControl{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    border-radius: 5px;
    font-family: var(--poppins) !important;
    background: var(--white);
    width: 205px;
}

.activities_select .MuiSelect-select:focus{
    border-radius: 6px;
}
.activities_select .MuiOutlinedInput-input{
    padding: 10px 60px 13px 15px;
    width: 100% !important;
    font-size: 16px;
    font-weight: 400px !important;
    color: var(--gray) !important;
}
/* .business_select .MuiSelect-outlined.MuiSelect-outlined{
    padding-right: 56px !important;
} */
.business_select svg {
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
    margin: 0px 10px 0px 10px;
}
.activities_select__wr .MuiFormLabel-root.Mui-focused{
  background-color: var(--light_blue) !important;
}

.activities_select__wr .MuiSelect-select:focus{
    background-color: transparent !important;
}

.activities_select__wr .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0,0,0,0.0);
    border-width: 0px;
}
.activities_select__wr .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0,0,0,0.0);
}

.activites__name__content .goal__activites__edit {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.goal__edit-popover-form { 
    width: 300px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;
}
.goal__edit-popover-form .chooseDuration.form-control {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    font-size: 16px;
    border-radius: 6px;
}

.goal__edit-popover-form input {
    margin: 0 !important;
    padding: 10px !important;
    box-sizing: border-box;
    border: 1px solid #ddd !important;
    box-shadow: unset !important;
}

.goal__edit-popover-form .MuiInput-underline:before,
.goal__edit-popover-form .MuiInput-underline:after {
    display: none;
}

.goal__edit-popover-form button {
    padding: 8px;
}

.goal__edit-popover-form .buttons {
    justify-content: flex-end;
    display: flex;
    padding: 10px 0;
}

.goal__edit-popover-form .buttons button {
    margin: 0 0 0 5px !important;
}


/* For Select End */

@media (max-width :1366px){
    .activites__name__label {
        width: 180px;   
    }
}


@media (max-width : 991px){
    .goalreport__top__button__wr button {
        padding: 11px 10px;
        width: 126px;
    }
    .goalreport__top__button__wr .ongoing__btn {
        padding: 7px 19px;
        min-width: 140px;
    }
}