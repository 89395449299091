.goalreport__wr{
    background: var(--white);
    margin: 30px 0;
    border-radius: 10px;
    display: flex;
    justify-content:space-between;
    padding: 20px;
    width: 100% !important;

}

.goalreport__left__wr{
    background: var(--gray_light);
    border-radius: 10px;
    padding: 10px;
    flex-basis: 378px;
    width: 378px;
    border: 1px solid #E5E8EF;
    
}
.goalreport__accordion{
    border-color: var(--white) !important;
    margin-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.goalreport__accordion .goalreport__accprdopm__summary{
    background-color: var(--white) !important;
    border-color: var(--white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 50px;
    min-height: 50px !important;
}

.goalreport__accordion .goalreport__accprdopm__summary:hover{
    background: #EBF2FE;
    box-shadow: 0px 20px 10px -15px rgba(60, 126, 243, 0.2);
}
.activities_select svg.MuiSvgIcon-root,
.goalreport__accprdopm__summary svg.MuiSvgIcon-root{
    color: var(--dark_blue);
}
.goalreport__accprdopm__summary.Mui-expanded svg.MuiSvgIcon-root{
    color: var(--white);
}
.goalreport__accprdopm__summary.Mui-expanded {
    background-color: var(--dark_blue) !important;
    border-color: var(--white);
    color: var(--white);
}
.Mui-expanded .MuiIconButton-label,
.Mui-expanded .goalreport__accordion__title{
    color: var(--white);
} 
.Mui-expanded .goalreport__accordion__lefticon{
    display: none;
}
.goalreport__accordion__lefticon__white{
    display: none;
}
.Mui-expanded .goalreport__accordion__lefticon__white{
    display: inline-block;
}
.goalreport__accordion__title{
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.16px;
    color: var(--gray);
    margin-left: 20px;
}
/* Add Goal Button Start */
.goalreport__top__button__wr button{
    padding: 11px 20px;
    width: 146px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    background: var(--light_blue);
    color: var(--white);
  }
  
  .goalreport__top__button__wr .MuiButtonBase-root.add__icon:hover{
    background-color: var(--light_blue);
   }
   
   .goalreport__top__button__wr .MuiButton-startIcon{
    margin-right: 10px;
  }
  
  .goalreport__top__button__wr .MuiButton-iconSizeMedium{
    background: #fff;
    color: var(--light_blue);
    border-radius: 50%;
    width: 20px;
    min-width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .goalreport__top__button__wr .add__icon svg{
    width: 16px !important;
  }
  
/* Add Goal Butoon End */
.goalreport__right{
    margin-left: 20px;
    flex: 1 auto;
  
}
.company__gaol__toggle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 50px;
    min-height: 50px !important;
    padding: 0 16px;
    margin-bottom: 10px;
    cursor: pointer;
}
.goalreport__right__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    flex: 1 auto;
}

.goalreport__right__top_title{
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.16px;
    color: var(--dark_blue);
}
.goalreport__top__button__wr .ongoing__btn{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: var(--dark_blue);
    padding: 7px 20px;
    background: var(--gray_light);
    display: inline-block;
    margin-right: 20px;
    border-radius: 5px;
    min-width: 161px;
    
  }
  .goalreport__top__button__wr .ongoing__i100{
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.16px;
    color: #3C7EF3;
    margin-left: 5px;
   
  }
  .goalreport__top__button__wr {
    display: flex;
    align-items: center;
}


/* Goal report main Content */
.goalreport__main__content{
    border-top: 1px solid #E7EFF0;
   margin: 18px 0 0px 0;
   padding: 22px 0 0 0;
}
.no__goal__addedd{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.16px;
    color:rgba(19, 49, 89, 0.65);
}


/* effort page css */
.effor__tab__topper .MuiTabs-flexContainer{
    height: 71px !important;
}
.effor__tab__topper .PrivateTabIndicator-colorSecondary-13 {
    background-color: var(--light_blue);
}

.effor__tab__topper .MuiTabs-indicator {
    background-color: var(--light_blue);
}

.effor__tab__topper .MuiTab-wrapper{
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    text-transform: capitalize !important;
}
.effor__tab__topper .Mui-selected .MuiTab-wrapper{
    color: var(--light_blue) !important;
}

.activities__checkbox .MuiCheckbox-root {
    color: #3C7EF3 !important;
    border: 0 !important;
}

.activities__checkbox .MuiFormControlLabel-root{
    margin-right: 1px !important;
}
.effort__report{
    background: #F6F7FB;
    border-radius: 10px;
}
.effort__top__wrapper{
    background-color: #fff;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
}
.effort__table__report {
    background: #fff;
    padding: 0 10px 10px 10px;
    border-radius: 10px;
}
.effort__report .MuiBox-root{
    padding: 14px !important;
}
.effort__table__report__container{}
.effort__main__content{
    margin: 6px 0 0px 0 !important;
    padding: 9px 0 0 0 !important;
}
.effort__table__report__container .MuiTableCell-root{
    padding: 0 11px !important;
}

.effort__table__report__container .MuiTableHead-root .MuiTableCell-root{
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: var(--dark_blue);

}
.effort__table__report__container .chart__table__body .MuiTableCell-root{
    color: rgba(19, 49, 89, 0.65);
}



/* @media (max-width : 1366px){ */
@media (max-width : 1919px){
    .effort__main__content {
        margin: -1px 0 0px 0 !important;
        padding: 9px 0 0 0 !important;
    }
    .effort__right__top{
        display: initial !important;
        height: auto !important;
    }
}
@media (max-width : 991px){
    .goalreport__left__wr{
        width: 234px;
    }
    .effort__wr{
        display: inline-flex !important;
        justify-content: flex-start !important;
        width: auto !important;
    }
    .goalreport__accordion__title{
        margin-left: 9px;
    }
    .goalreport__top__button__wr button {
        font-size: 12px;
        padding: 7px 8px;
        width: 105px;
    }
    .goalreport__top__button__wr .MuiButton-iconSizeMedium {
        width: 18px;
        min-width: 18px;
        height: 18px;
    
    }
    .activites__name__label,
    .activites__name__content{
        font-size: 12px;
    }
    .activities_select .MuiOutlinedInput-input{
        font-size: 12px !important;
    }
    .goalreport__right__top_title{
        font-size: 16px;
    }
    .goal__activies__top .goal__activites__title{
        font-size: 16px;
    }
    .goalreport__top__button__wr .ongoing__btn {
        padding: 1px 19px;
        min-width: 123px;
        font-size: 12px;
    }
    .goalreport__accordion__title{
        font-size: 12px;
    }
    .ongoing__i100{
        font-size: 18px !important;
    }
    .activities_select__wr .MuiInputBase-formControl{
        width: 174px !important;
    }
    .activites__name__label {
        width: 187px;
    }



    /* effort */
    .effort__table__body .MuiTableCell-root{
        font-size: 12px !important;
    }
    .activites__effort__h2{
        font-size: 16px;
    }
    .activites__effort__li{
        font-size: 12px;
    }
    .activites__side__effort{
        margin-left: 0;
    }
    .effort__table__report__container .MuiTableHead-root .MuiTableCell-root{
        font-size: 12px;
    }

    .effor__tab__topper .MuiTab-wrapper{
        font-size: 12px;
    }
    .effor__tab__topper .MuiTabs-flexContainer{
        height: 0px !important;
    }
}

.deal_value_validation_text{
    font-size: 10px;
    color: #d00;
}




