.activites__side__effort{
    margin-left: 40px;
}
.activites__effort1{
    margin-top: 30px;
}
.activites__effort__h2{
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    font-family: var(--poppins);
    color: var(--dark_blue);
    letter-spacing: 0.16px
}
.activites__effort__li{
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    font-family: var(--poppins);
    color: rgba(19, 49, 89, 0.65);
    letter-spacing: 0.16px;
    padding-bottom: 10px;
}
.activites__effort__li a{
    color: rgba(10, 13, 17, 0.65);
}

.activites__effort__li a:hover{
    color: #546376;
}
.activites__pipeline__wr{
    margin-top: 20px;
}
.activites__salse__wr{
    margin-top: 20px;
}

/* For Select Start */
.activities_select__wr1 .MuiInputBase-formControl{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    border-radius: 5px;
    font-family: var(--poppins) !important;
    background: var(--white);
    border: 1px solid rgba(84, 99, 118, 1);
    height: 36px;
}

.activities_select1 .MuiSelect-select:focus{
    border-radius: 6px;
}
.activities_select1 .MuiOutlinedInput-input{
    padding: 10px 60px 13px 15px;
    width: 100% !important;
    font-size: 16px;
    font-weight: 400px !important;
    color: var(--gray) !important;
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.16px;
}
/* .business_select .MuiSelect-outlined.MuiSelect-outlined{
    padding-right: 56px !important;
} */

.activities_select__wr1 .MuiFormLabel-root.Mui-focused{
  background-color: var(--light_blue) !important;
}

.activities_select__wr1 .MuiSelect-select:focus{
    background-color: transparent !important;
}

.activities_select__wr1 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0,0,0,0.0);
    border-width: 0px;
}
.activities_select__wr1 .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0,0,0,0.0);
}


.goalreport__left__wr .sidebar__menu_list {
    position: relative;
    margin-bottom: 7px;
}

.goalreport__left__wr .sidebar__menu_list span.select-box-icon {
    position: absolute;
    left: 7px;
    top: 12px;
}

.goalreport__left__wr .sidebar__menu_list select.form-control {
    padding-left: 30px;
    font-weight: 600;
    color: #26292c;
    font-size: 15px;
    height: 100%;
    display: block;
}

.activites__effort__ul li.activites__effort__li.active a {
    color: #095d09 !important;
}



/* Effort report */
.effort__tab__button{
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: var(--light_blue);
    
}
.effor__tab__topper .MuiTab-root{
    min-width: 0;
    padding: 0 15px;
}
/* .effort__tab__contnet .MuiBox-root-14{
    padding: 0 !important;
} */
.effort__tab__tabpannel{
    padding: 0;
}



.effor__tab__topper button:focus {
    outline: none;
    background-color: transparent !important;
}

.effort__form__select
.MuiInputBase-formControl{
  border-color:#546376 !important;
}


.goal_no_data_text_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.goal_no_data_text_wrapper .no_data__loading {
    font-size: 30px;
    margin-right: 10px !important;
}

.goal_no_data_text_wrapper .no_data__loading_title {
    font-size: 25px;
}

.goal_no_data_text_wrapper .no_data__loading_subtitle {
    font-size: 20px;
    text-align: center;
}



@media (max-width : 991px){
    .activities_select__wr1 .MuiInputBase-formControl{
        line-height: 24px !important;
        font-size: 12px !important;
    }
    .activities_select1 .MuiOutlinedInput-input{
        font-size: 12px !important;
    }
}

