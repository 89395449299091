.call__log_datepicker .rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.call__log_datepicker .select-wrapper {
  width: 100%;
  height: auto;
  display: block;
}

.call__log_datepicker .rdrDateRangePickerWrapper {
  /* position: absolute; */
  z-index: 9;
  width: 100%;
  /* display: flex; */
}

.call__log_datepicker .rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.call__log_datepicker .rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.call__log_datepicker .rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
  }

.call__log_datepicker .rdrDateDisplayItem input{
    text-align: inherit
  }

.call__log_datepicker .rdrDateDisplayItem input:disabled{
      cursor: default;
    }

.call__log_datepicker .rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.call__log_datepicker .rdrMonthAndYearPickers{
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.call__log_datepicker .rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.call__log_datepicker .rdrMonths{
  display: flex;
}

.call__log_datepicker .rdrMonthsVertical{
  flex-direction: column;
}

.call__log_datepicker .rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.call__log_datepicker .rdrMonth{
  width: 27.667em;
}

.call__log_datepicker .rdrWeekDays{
  display: flex;
}

.call__log_datepicker .rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.call__log_datepicker .rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.call__log_datepicker .rdrInfiniteMonths{
  overflow: auto;
}

.call__log_datepicker .rdrDateRangeWrapper{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.call__log_datepicker .rdrDateInput {
  position: relative;
}

.call__log_datepicker .rdrDateInput input {
    outline: none;
  }

.call__log_datepicker .rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
  }

.call__log_datepicker .rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.call__log_datepicker .rdrDayNumber {
  display: block;
  position: relative;
}

.call__log_datepicker .rdrDayNumber span{
    color: #1d2429;
  }

.call__log_datepicker .rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .call__log_datepicker .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.call__log_datepicker .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.call__log_datepicker .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.call__log_datepicker .rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.call__log_datepicker .rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.call__log_datepicker .rdrStaticRange{
  font-size: inherit;
}

.call__log_datepicker .rdrInputRange{
  display: flex;
}