.formControl {
  width: 250px !important;
}

.formControl:not(:last-child) {
  margin-right: 10px;
}

.audio {
  height: 36px !important;
  width: 230px !important;
}

.noteText{
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 9px;
}