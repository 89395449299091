.awesome__scroll_bar3::-webkit-scrollbar-track{
    margin-top: 0 !important;
}

.awesome__scroll_bar3{
    height: 100%;
    /* height: -moz-calc(100%);
    padding-bottom: 80px; */
    overflow-y: auto !important;
}
.awesome__scroll_bar3::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(60, 126, 243, 0.1);
    border-radius: 10px;
    background-color: rgba(60, 126, 243, 0.1);;
}
.awesome__scroll_bar3::-webkit-scrollbar-track{
    margin-top: 0px;
}
.awesome__scroll_bar3::-webkit-scrollbar{
    width: 12px;
}
/* body.active__minimal_sidebar .awesome__scroll_bar::-webkit-scrollbar{
    width: 6px;
} */
.awesome__scroll_bar3::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(19, 49, 89, 0.5);;
    background-color: var(--dark_blue);
}

.add__column__checkbox{
    background: var(--white);
    margin-bottom: 10px;
    padding: 2px 10px;
    border-radius: 5px;
}
.add__column__checkbox:last-child{
    margin-bottom: 0 !important;
}
.active__add__column__checkbox{
    background: rgba(60, 126, 243, 0.1) !important;
    border-radius: 5px;
}
.add__column__checkbox:hover{
    background: rgba(60, 126, 243, 0.1) !important;
    border-radius: 5px;
}
.add__column__checkbox .MuiCheckbox-root{
    color: #3C7EF3 !important;
    border: 0 !important;
}
.active__add__column__checkbox .MuiCheckbox-colorPrimary.Mui-checked{
    /* color: var(--white) !important;
    border: 1px solid #3C7EF3;
    border-radius: 2px; */
}
.add__column__checkbox .MuiFormControlLabel-label{
    font-family: var(--poppins) !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: var(--gray);
}

@media(max-width: 992px){
    .awesome__scroll_bar3::-webkit-scrollbar{
        width: 6px;
    }
    .modal__body__reportcolm {
        padding: 20px 13px !important;
    }
}