.ReportModule-container-parent-class .user-carrier-bill-table-paginate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
}
.ReportModule-container-parent-class .dataTables_paginate.paging_simple_numbers {
    display: flex;
    align-items: center;
}
.ReportModule-container-parent-class .dataTables_paginate.paging_simple_numbers a {
    cursor: pointer;
}
.ReportModule-container-parent-class .dataTables_paginate.paging_simple_numbers a#bill-table_previous, .dataTables_paginate.paging_simple_numbers a#bill-table_next {
    width: 40px;
    justify-content: center;
    display: flex;
}
.ReportModule-container-parent-class .dataTables_paginate.paging_simple_numbers span .paginate_button.page_number {
    font-size: 16px !important;
    color: inherit;
    min-width: 35px;
    display: inline-block;
    text-align: center;
    min-height: 35px;
    line-height: 35px;
    border-radius: 2px;
}
.ReportModule-container-parent-class .dataTables_paginate.paging_simple_numbers span .paginate_button.page_number.current {
    color: #fff;
    background: var(--topbar-background);
}

.dataTables_wrapper .not-found {
    font-size: 25px;
    height: 75vh;
}

.ReportModule-container-parent-class .transaction__report {
    padding: 10px 20px 0;
    background: #fff;
    border-radius: 10px;
    margin: 20px 0;
}
.ReportModule-container-parent-class .dataTables_wrapper thead {
    /*! background: #f00; */
}
.ReportModule-container-parent-class .dataTables_wrapper tr {
    padding: 0 20px !important;
}
.ReportModule-container-parent-class table td, .ReportModule-container-parent-class table th {
    color: rgba(0, 0, 0, 0.87) !important;
}
.ReportModule-container-parent-class .transaction__report .section__top {
    padding: 10px 0 15px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}




