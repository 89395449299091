.goalreport__accordion .MuiCollapse-wrapper{
    margin-bottom: 10px;
}
.goalreport__accordion.Mui-expanded.MuiPaper-root{
    border-radius: 6px;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.04);
}
.team__goal__wr{
    padding: 20px 20px 0 20px;
    background-color: #fff
}
.team__accordian__details{
    margin-bottom: 10px;
}
.team__goal__typography{
    width: 100%;
}
.team__goal__wr .team__goal__list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 0.25px solid #BDBDBD;
    padding-bottom: 5px;
    padding-top: 10px;

}
.team__goal__list:last-child{
    border-bottom: 0px !important;
}
.team__goal__list:first-child{
    padding-top: 0px;
}
.team__goal__left__wr {
    display: flex;
    justify-content: center;
    align-items: center;
}

.team__goal__list .team__goal__left__icon{
    margin-right: 26px;
}

/* Search Start */
.team__goal__serarch__icon svg{
    color: var(--light_blue);
}
.team__goal__serarch__form .MuiPaper-rounded{}
.team__goal__serarch__form .makeStyles-root-19{
    padding: 0px !important;
   
}
.team__goal__serarch__form input{
    height: 40px !important;
    border-bottom: 0 !important;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.0) !important;
    margin: 0 !important;
    padding-left: 18px !important;
    color: rgba(84, 99, 118, 1);
}
.team__goal__serarch__form .MuiPaper-elevation1{
    box-shadow: 0 0 0 0 rgba(0,0,0,0.0) !important;
    border: 1px solid rgba(60, 126, 243, 0.1);
    border-radius: 20px;
    padding: 0 !important;
    width: 87%;
    height: 40px;
}
.team__goal__serarch__form {
    text-align: center;
    justify-content: center;
    display: flex;
}
/* Search End */
.team__goal__list .team__goal__left__icon__svg{
    height: 30px;
    width: 30px;
    background: #E5E8EF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.team__goal__list .team__goal__count{
    font-family: var(--poppins);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.16px;
    height: 30px;
    width: 30px;
    background: #E5E8EF;
    color: var(--dark_blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.team__goal__list .team__goal__left__icon {}
.team__goal__list .team__goal__middle {}
.team__goal__wr .team__goal__name{
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.16px;
    color: var(--dark_blue);
}
.team__goal__wr .team__goal__member{
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.16px;
    color: var(--gray);
}

.team__accordian__details{
    padding: 0 !important;
}


@media (max-width : 991px){
    .team__goal__serarch__form .MuiPaper-elevation1{
        width: 100%;
        height: 40px;
    }
    .team__goal__wr .team__goal__name{
        font-size: 12px;
    }
    .team__goal__wr .team__goal__member{
        font-size: 10px;
    }
    .team__goal__list .team__goal__count{
        font-size: 12px;
    }
    
}



