
.chart__table__wr{
    margin: 14px 0 4px 0;
    border-top: 1px solid#E5E8EF;
}

.chart__table__wr .MuiTableContainer-root {
    /* overflow-x: scroll !important; */
    /* max-width: 1920px; */
}
.chart__table{
    margin-top: 9px !important;
}
.MuiTable-root.chart__table{
    border-spacing: 0 10px;
}
/* .MuiTable-root.chart__table{
    min-width: 1700px !important;
}

.chart__table th.MuiTableCell-root{
    width: 9% !important;
} */
/* .chart__table th,
.chart__table tr>td{
    text-align: center;
} */
.chart__table .MuiTableCell-root{
    border-bottom: 0px;
    padding: 11px;
}
.chart__table .MuiTableHead-root .MuiTableCell-root{
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.14px;
    color: var(--dark_blue);

}
.chart__table .chart__table__body .MuiTableCell-root{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.14px;
    color: var(--dark_blue);
}
.chart__table .MuiTableCell-stickyHeader{
    background-color: var(--white);
}

.chart__table__body tr.MuiTableRow-root {
    background: #F6F7FB;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box;
    border-radius: 10px;
}

.chart__table__body tr.MuiTableRow-root td:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.chart__table__body tr.MuiTableRow-root td:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

span.table__user__short {
    width: 30px;
    height: 30px;
    background: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px; 
    line-height: 21px;
    margin-right: 8px;
}


.chart__table__wr .makeStyles-container-8::-webkit-scrollbar-track{
    -webkit-box-shadow : inset 0 0 6px rgba(0,0,0,0.3) !important;
    border-radius: 10px !important;
    background-color: var(--dark_blue) !important;
}

/*********************
scroll bar csc
***********************/
table.awesome__scroll_bar2{
    /* table-layout: fixed !important; */
    
}
table.awesome__scroll_bar2 th,
table.awesome__scroll_bar2 td
{
   max-width: 150px !important;
  
}
.awesome__scroll_bar2{
    height: 100%;
    /* height: -moz-calc(100%);
    padding-bottom: 80px; */
    overflow-y: auto !important;
    overflow-x: auto !important;
    white-space: nowrap;
}
.awesome__scroll_bar2::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(60, 126, 243, 0.1);
    border-radius: 10px;
    background-color: rgba(60, 126, 243, 0.1);;
}
.awesome__scroll_bar2::-webkit-scrollbar-track{
    margin-top: 56px;
}
.awesome__scroll_bar2::-webkit-scrollbar{
    width: 12px;
}
/* body.active__minimal_sidebar .awesome__scroll_bar::-webkit-scrollbar{
    width: 6px;
} */
.awesome__scroll_bar2::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(19, 49, 89, 0.5);;
    background-color: rgba(19, 49, 89, 0.5);;
}
@media(max-width: 992px){
    .awesome__scroll_bar2::-webkit-scrollbar{
        width: 6px;
    }
    span.table__user__short{
        width: 20px;
        height: 20px;
        font-size: 8px;
        line-height: 12px;
        font-weight: 500;
        margin-right: 5px;
    }
}


