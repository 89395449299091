/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
:root {
    --green_general: #6FCF97;
    --red_general: #FF5858;
    --blue_general: #4D7CFE;
    --border_general: #E5E8EF;
    --border_general1: #E7EFF0;

}
.ReportModule-container-parent-class h1,
.ReportModule-container-parent-class h2, 
.ReportModule-container-parent-class h3,
.ReportModule-container-parent-class h4,
.ReportModule-container-parent-class h5,
.ReportModule-container-parent-class h6,
.ReportModule-container-parent-class p{
    padding: 0;
    margin: 0;
}

.MuiInputBase-root{
    font-family: var(--poppins) !important;
}

.repot_dropdown_item.MuiButtonBase-root {
    font-family: var(--poppins) !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color:rgb(19, 49, 89) !important;
}

.business__report__wr{
    background: var(--white);
    border-radius: 10px;
    padding: 26px 20px 1px 20px;
    /* margin: 30px 0 10px 0 !important */
}
.business__report__top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 0 26px 0 !important;
    align-items: center;
    border-bottom: 1px solid var(--border_general1);
}
.business__report__top .section__title{
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 500;
    color: var(--dark_blue);
    line-height: 36px;
    letter-spacing: 0.16px;
    
}
.business_select__wr .MuiInputBase-formControl{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    border-radius: 6px;
    font-family: var(--poppins) !important;
}
.business_select__wr .select__blue__back{
    color: var(--white) !important;
    background: var(--light_blue);
}
.select__blue__back .MuiSelect-icon{
    color: var(--light_blue);
}
.select__dark__back{
    color: var(--white) !important;
    background: var(--dark_blue);
}
.select__dark__text{
    margin-right: 30px;
}
.business_select .MuiSelect-select:focus{
    border-radius: 6px;
}
.business_select__wr .MuiOutlinedInput-input{
    padding: 13px 60px 13px 15px;
    width: 100% !important;
}
.business_select .MuiSelect-outlined.MuiSelect-outlined{
    padding-right: 52px !important;
}
.business_select svg {
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
    margin: 0px 10px 0px 10px;
}

/* Select Custom Start */

.business_select__wr .MuiFormLabel-root.Mui-focused{
  background-color: var(--light_blue) !important;
}

.business_select__wr .MuiSelect-select:focus{
    background-color: transparent !important;
}

/* Select Custom End */

/* Button Custom Css */
.business_select__wr .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0,0,0,0.0);
    border-width: 0px;
}
.business_select__wr .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0,0,0,0.0);
}



/* Chart content */
.business__report__content {
    margin: 28px 0 102px 0;
}



@media(max-width: 1366px){
    .chart__table .MuiTableHead-root .MuiTableCell-root{
        font-size: 12px;
    }
    .MuiPaper-root ul.MuiList-root li.MuiButtonBase-root{
        font-weight: 400;

    
    }
    .chart__table .chart__table__body .MuiTableCell-root{
        font-size: 14px;
    }
}

@media(max-width: 991px){
    .business_select svg{
        width: 20px !important;
        height: 20px !important;
    }
    .MuiPaper-root ul.MuiList-root li.MuiButtonBase-root{
        font-size: 12px;
    
    }
    .business_select .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 41px !important;
    }
    .business_select__wr .MuiOutlinedInput-input {
        padding: 10px 60px 7px 10px;
        width: 100% !important;
    }
    .business_select__wr .MuiInputBase-formControl{
        line-height: 18px !important;
    }
 }


@media(max-width: 768px){
    .chart__table .MuiTableHead-root .MuiTableCell-root{
        font-size: 9px;
        font-weight: 400;
        line-height: 14px;
    }
    .chart__table .chart__table__body .MuiTableCell-root{
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
    }
    .business__report__top .section__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 3%;
    }
    .business_select__wr .MuiInputBase-formControl{
        font-size: 12px;
    }
}

.min-height-500{
    min-height: 500px;
}


